import React from 'react';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {Paper} from "@mui/material";

const AboutScreen = () => {
    return (
        <Paper
            sx={{
                padding: {xs: '2% 0 50%', sm: '2% 5% 50%', md: '6% 2% 27%', lg: '6% 18% 27%'}
            }}
        >
            <Box
                id={'about'}
                sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', sm: 'column', md: 'row', lg: 'row'},
                    padding: {xs: 4, sm: 4},
                    scrollBehavior: 'smooth'
                }}
            >
                <Container
                    sx={{
                        width: {xs: '100%', sm: '100%', md: '60%', lg: '55%'}
                    }}
                >
                    <Typography
                        mb={4}
                        sx={{
                            fontSize: {xs: 30, sm: 30, md: 50, lg: 50},
                            fontWeight: 800,
                            color: 'rgb(152, 107, 78)'
                        }}
                        className={'about'}
                    >
                        Чому саме ми
                    </Typography>
                    <Typography
                        mb={4}
                        sx={{
                            fontSize: {xs: 16, sm: 16, md: 20, lg: 20},
                            color: 'black'
                        }}
                    >
                        – Наша робота базується на збереженні традиційних підходів.
                    </Typography>
                    <Typography
                        mb={4}
                        sx={{
                            fontSize: {xs: 16, sm: 16, md: 20, lg: 20},
                            color: 'black'
                        }}
                    >
                        – Ми вклали максимум зусиль у деталі: аромати, освітлення, звуки, з метою сформувати унікальну атмосферу у наших приміщеннях.
                    </Typography>
                    <Typography
                        mb={2}
                        sx={{
                            fontSize: {xs: 16, sm: 16, md: 20, lg: 20},
                            color: 'black'
                        }}
                    >
                        – Наша діяльність спрямована на злагодження різноманітних індивідів, створення нових ініціатив та відчуження певної суми коштів на благодійність.
                    </Typography>
                </Container>
                <Container
                    sx={{
                        width: {xs: '100%', sm: '100%', md: '35%', lg: '35%'},
                        pt: {xs: 0, sm: 0, md: 3, lg: 3}
                    }}
                >
                    <Typography
                        mb={4}
                        sx={{
                            fontSize: {xs: 16, sm: 16, md: 18, lg: 18},
                            color: '#4d4d4d'
                        }}
                    >
                        Суть наших традиційних підходів полягає у поєднанні практичності і вишуканості. Ми створюємо зачіски, що допомагають чоловікам почуватися легко та впевнено кожен день.
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: {xs: 16, sm: 16, md: 18, lg: 18},
                            color: '#4d4d4d'
                        }}
                    >
                        Кожна деталь інтер'єру в Kapper має своє місце, що доповнює загальну атмосферу, що сприяє народженню нових ідей та проектів. Ми несемо відповідальність за свою діяльність і тому виділяємо частину коштів з кожної стрижки на благодійність.
                    </Typography>
                </Container>
            </Box>
        </Paper>
    );
};

export default AboutScreen;
