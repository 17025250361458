import icon1 from '../assets/masters/IMG_9031-min.JPG'
import icon2 from '../assets/masters/IMG_9032-min.JPG'
import icon4 from '../assets/masters/IMG_9034-min.JPG'
import icon7 from '../assets/masters/IMG_9037-min.JPG'
import icon8 from '../assets/masters/IMG_9038-min.JPG'

export const MASTERS = [
  {
    name: 'Олег Pro Barber',
    src: icon4
  },
  {
    name: 'Вусал Top Barber',
    src: icon8
  },
  {
    name: 'Михайло Top Barber',
    src: icon2
  },
  {
    name: 'Денис Barber',
    src: icon1
  },
  {
    name: 'Дмитро Barber',
    src: icon7
  },
]
