import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MasonryImageList from "./MasonryImageList";

const LookbookScreen = () => {
    return (
        <Box
            id={'lookbook'}
            sx={{
                transform: 'translateY(-28%)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                scrollBehavior: 'smooth'
            }}
        >
            <Box
                sx={{
                    maxWidth: 580,
                    mb: 3,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography
                    sx={{
                        fontSize: {xs: 30, sm: 30, md: 130, lg: 130},
                        fontWeight: 400,
                        color: 'rgb(0,0,0)',
                        fontStyle: 'italic',
                        lineHeight: 1,
                        mb: {xs: 2},
                        alignSelf: 'center'
                    }}
                    className={'lookbook'}
                >
                    Lookbook
                </Typography>
                <Typography
                    sx={{
                        fontSize: {xs: 16, sm: 16, md: 20, lg: 20},
                        color: 'black',
                        fontStyle: 'italic'
                    }}
                >
                    Ми втілюємо більше, ніж просто зачіску чи гоління.
                </Typography>
                <Typography
                    sx={{
                        fontSize: {xs: 16, sm: 16, md: 20, lg: 20},
                        color: 'black',
                        fontStyle: 'italic'
                    }}
                >
                    Ми трансформуємо вас у найкращу версію самих себе.
                </Typography>
            </Box>
            <MasonryImageList/>
        </Box>
    );
};

export default LookbookScreen;
