import photo1 from '../assets/lookbook/agustin-fernandez-1Pmp9uxK8X8-unsplash.jpg'
import photo2 from '../assets/lookbook/hair-spies-TNhm6uVurpU-unsplash.jpg'
import photo3 from '../assets/lookbook/arthur-humeau-pu20JkUx--A-unsplash.jpg'
import photo4 from '../assets/lookbook/hair-spies-lmZ_JVfbmY4-unsplash.jpg'
import photo5 from '../assets/lookbook/jeppe-monster-T_gTN3Po9RQ-unsplash.jpg'
import photo6 from '../assets/lookbook/allef-vinicius-IvQeAVeJULw-unsplash.jpg'

export const LOOKBOOK_IMAGES = [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6
]
