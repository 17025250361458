import React from 'react';
import Button from "@mui/material/Button";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import Container from "@mui/material/Container";

const MainSocialIcons = ({sx}) => {
    const openInNewTab = () => {
        window.open('https://instagram.com/kappers_ua?igshid=MzRlODBiNWFlZA==', '_blank', 'noopener,noreferrer');
    };

    return (
        <Container
            disableGutters={true}
            maxWidth={false}
            sx={sx}
        >
            <Button
                sx={{
                    padding: '7px 0!important'
                }}
            >
                <InstagramIcon onClick={openInNewTab} fontSize={'large'} sx={{margin: '0 10px'}}/>
            </Button>
        </Container>
    );
};

export default MainSocialIcons;
