import icon1 from '../assets/service-icons/1_.png'
import icon2 from '../assets/service-icons/2____.png'
import icon3 from '../assets/service-icons/3____.png'
import icon4 from '../assets/service-icons/4__.png'
import icon5 from '../assets/service-icons/5___.png'

export const SERVICES = [{
    src: icon1,
    title: 'Cтрижка',
    desrciption: 'Класична та вишукана. Виражає стиль та індивідуальність',
    price: 'від 300 грн',
    duration: '1 година'
}, {
    src: icon2,
    title: 'Cтрижка бороди та насадка',
    desrciption: 'Підкорегуємо деталі та форму для відмінного' + ' вигляду.',
    price: 'від 400 грн',
    duration: '1.5 години'
}, {
    src: icon3,
    title: 'Стрижка та стрижка бороди',
    desrciption: 'Все що потірбно чоловіку, щоб виглядати чудово',
    price: 'від 500 грн',
    duration: '1.5 години'
}, {
    src: icon4,
    title: 'Королівське гоління',
    desrciption: 'Гоління безпечною бритвою із застосуванням гарячих' + ' рушників',
    price: 'від 500 грн',
    duration: '1 година'
}, {
    src: icon5,
    title: 'Стрижка під 2-3 насадки',
    desrciption: 'Тут нічого зайвого. Швидко та вільно',
    price: 'від' + ' 250 грн',
    duration: '45 хвилин'
},]
