import React, {useEffect, useLayoutEffect, useRef} from 'react';
import {GoogleMap, useJsApiLoader} from "@react-google-maps/api";
import {GOOGLE_MAPS_API_KEY} from "../../consts/googleApiKey";
import { Marker } from '@react-google-maps/api';
import {useMediaQuery} from "@mui/material";
import {useIsInViewport} from "../../hooks/useIsInViewport";
import MapInfo from "../UI/MapInfo";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const containerStyle = {
    width: '100%',
    height: '60vh',
    position: 'relative'
};

const center = {
    lat: 46.43688188569425,
    lng: 30.743866513495046
};

const Map = () => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_API_KEY
    })

    const isThousandLessWidth = useMediaQuery('(min-width:1000px)');

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const openInNewTab = () => {
        window.open('https://www.google.com/maps/place/1A,+%D0%90%D0%B4%D0%BC%D0%B8%D1%80%D0%B0%D0%BB%D1%8C%D1%81%D0%BA%D0%B8%D0%B9+%D0%BF%D1%80%D0%BE%D1%81%D0%BF.,+1%D0%90,+%D0%9E%D0%B4%D0%B5%D1%81%D1%81%D0%B0,+%D0%9E%D0%B4%D0%B5%D1%81%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+65000/@46.4367488,30.7413238,17z/data=!3m1!4b1!4m6!3m5!1s0x40c633940e447bf1:0xa0e9ed54da29b404!8m2!3d46.4367451!4d30.7438987!16s%2Fg%2F1tddqpzq?entry=ttu', '_blank', 'noopener,noreferrer');
    };

    return isLoaded ? (
        <Box
            sx={{
                '.gm-style': {
                    zIndex: '12!important'
                },
                marginTop: {xs: '-35%', sm: '-35%', md: '-15%', lg: '-15%'}
            }}
        >
            <Typography
                id={'contacts'}
                sx={{
                    margin: '0 auto',
                    mb: 10,
                    fontSize: {xs: '30px', sm: '45px', md: '45px', lg: '45px'},
                    fontWeight: 600,
                    width: 'max-content'
                }}
            >
                Де ми знаходимось?
            </Typography>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={19}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{mapId: 'cc25687668959379'}}
            >
                <Marker position={center} onClick={openInNewTab}/>
                {isThousandLessWidth && isLoaded &&
                    <MapInfo/>
                }
                <></>
            </GoogleMap>
        </Box>
    ) : <></>
};

export default Map;
