import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MainSocialIcons from "./MainSocialIcons";

const MapInfo = () => {
    const openInNewTab = () => {
        window.open('https://www.google.com/maps/place/1A,+%D0%90%D0%B4%D0%BC%D0%B8%D1%80%D0%B0%D0%BB%D1%8C%D1%81%D0%BA%D0%B8%D0%B9+%D0%BF%D1%80%D0%BE%D1%81%D0%BF.,+1%D0%90,+%D0%9E%D0%B4%D0%B5%D1%81%D1%81%D0%B0,+%D0%9E%D0%B4%D0%B5%D1%81%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+65000/@46.4367488,30.7413238,17z/data=!3m1!4b1!4m6!3m5!1s0x40c633940e447bf1:0xa0e9ed54da29b404!8m2!3d46.4367451!4d30.7438987!16s%2Fg%2F1tddqpzq?entry=ttu', '_blank', 'noopener,noreferrer');
    };

    return (
        <Box
            sx={{
                width: '30vw',
                height: '300px',
                position: 'absolute',
                left: '52vw',
                top: '50%',
                transform: 'translateY(-50%)',
                background: '#000',
                borderRadius: '10px',
                padding: 5,
                zIndex: 12
            }}
        >
            <Typography
                sx={{
                    color: '#be8b60',
                    fontSize: '34px',
                    fontWeight: 600,
                    mb: 2,
                    cursor: 'pointer'
                }}
                onClick={openInNewTab}
            >
                KAPPERS
            </Typography>
            <Typography
                sx={{
                    fontSize: '21px',
                    mb: 1,
                    cursor: 'pointer'
                }}
                onClick={openInNewTab}
            >
                м. Одеса, Адмиральский проспект 1а
            </Typography>
            <Typography
                sx={{
                    fontSize: '21px',
                    mb: {md: 3, lg: 5}
                }}
            >
                <a
                    href="tel:+38 067 86 42 108"
                    style={{textDecoration: 'none', color: 'inherit'}}
                >
                    +38 093 56 73 236
                </a>
            </Typography>
            <MainSocialIcons sx={{
                '.MuiSvgIcon-root': {
                    padding: 0,
                    margin: 0
                },
                '.MuiButtonBase-root': {
                    padding: 0
                }
            }}/>
        </Box>
    );
};

export default MapInfo;
